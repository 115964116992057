<template>
	<!--资产配件详情-->
	<div class="oDetail" v-loading="detailLoading">
		<div class="detailMain">
			<div class="infoBox" >
				<div class="top_des txNowrap">资产配件详情</div>
				<div class="mf_form">
					<TForm ref="refFrom"  :model="htmlDatas" :formlist="formlist" :rowGutter='16'></TForm>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		name: "OrderDetail",
		components: {TForm},
		mixins: [],
		props: {},
		data() {
			return {
				htmlDatas:{},
				formlist:[
					{ name: '备品备件编号', keys: 'materielId', value: '', type: 'txRow', flex: 6, },
					{ name: '备品备件名称', keys: 'materielName', value: '', type: 'txRow', flex: 18, },
					{ name: '备品备件型号', keys: 'currentModel', value: '', type: 'txRow', flex: 6, },
					{ name: '主资产', keys: 'liaison', value: '', type: 'txRow', flex: 18, },
					{ name: '入库日期', keys: 'purchaseDate', value: '', type: 'txRow', flex: 6, },
					{ name: '金额', keys: 'materielPrice', value: '', type: 'txRow', flex: 18, },
					{ name: '单位', keys: 'materielUnit', value: '', type: 'txRow', flex: 6, },
					{ name: '库管', keys: 'storekeeperName', value: '', type: 'txRow', flex: 18, },
					{ name: '说明', keys: 'description', value: '', type: 'txRow', flex: 6, },
				],
				detailId:'',
				detailLoading:false,
			};
		},
		computed: {

		},
		watch:{},
		created() {
			const {dataId}=this.$route.query;
			if(dataId){
				this.detailId=dataId;
			}
//			this.$store.dispatch('getEmployeeDatas');//获取所有部门人员
//			this.$store.dispatch('getAllDevices');
			this.initHtml();
			
		},
		mounted() {},
		methods: {
			
			initHtml(){
				this.getDetailById();
			},
			
			async	getDetailById(){
				//详情
				if(!this.detailId) return;
	      		this.detailLoading = true;
		     	let bkData = await this.ApiHttp('/base/tenant/materiel/get/'+this.detailId);
		     	this.detailLoading = false;
		      	if(bkData){
		      		this.htmlDatas = {...bkData};
		      	}
			},
			
			
		},
	};
</script>
<style type="text/css" lang="scss" scoped>
	.oDetail {
		height: calc(100vh - 60px);
		overflow-y: auto;
		box-sizing: border-box;
		padding: 0 24px;
		.evbtns{}
		.checkInfo{
			margin-top: 16px;
		}
		.detailMain{
			height: 100%;
			background: #FFFFFF;
			border-radius: 4px;
			padding: 8px 32px;
			.infoBox {
				height: 95%;
				background: #F8F8F8;
				padding: 12px 32px;
				margin: 16px 0;
				border-radius: 4px;
				.top_des{
					font-size: 16px;
					font-weight: bold;
					color: #2F3941;
					padding: 18px 0;
				}
				.if_name {
					padding: 12px 0;
					color: #2F3941;
					font-size: 16px;
					margin-top: 8px;
					font-weight: bold;
				}
				.editBtn {
					margin-left: 10px;
				}
				.txBlue i {
					margin-left: 6px;
					margin-right: 2px;
				}
			}
		}
	}
</style>
<style type="text/css" lang="scss">
.mf_form{
					.Y-myFrom{
						.t_row {
							margin: 12px 0;
					
							.tr_name {
								font-size: 12px !important;
								color: #909399 !important;
								font-weight: inherit;
							}
						}
						.trCode {
							padding: 12px 0;
							._name {
								font-size: 12px;
								color: #909399;
								font-weight: inherit;
								line-height: 20px;
							}
							._val {
								font-size: 14px;
								color: #2F3941;
								font-weight: bold;
								line-height: 28px;
								.el-button {
									padding: 0;
								}
							}
						}
					}
					
					
				} 
</style>