var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.detailLoading,
          expression: "detailLoading",
        },
      ],
      staticClass: "oDetail",
    },
    [
      _c("div", { staticClass: "detailMain" }, [
        _c("div", { staticClass: "infoBox" }, [
          _c("div", { staticClass: "top_des txNowrap" }, [
            _vm._v("资产配件详情"),
          ]),
          _c(
            "div",
            { staticClass: "mf_form" },
            [
              _c("TForm", {
                ref: "refFrom",
                attrs: {
                  model: _vm.htmlDatas,
                  formlist: _vm.formlist,
                  rowGutter: 16,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }